// $primary: #a51616;
$primary: #00b0ea; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
@import url('https://fonts.googleapis.com/css?family=Inconsolata|Rubik');
@mixin angle($pseudo, $flip: false, $angle: 2deg) {
 body {
	 font-family: 'Rubik', sans-serif;
 }
  // Possible values for $pseudo are: before, after
  @if $pseudo == 'before' or $pseudo == 'after'{
    position: relative;
    // z-index: 1;
    $selector: if($pseudo == '&:before', '&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
          outline: 1px solid transparent;

        } @else {
          transform: skewY($angle);
          outline: 1px solid transparent;
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          outline: 1px solid transparent;
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          outline: 1px solid transparent;
          transform-origin: 100%;
        }
      }
    }
  }
}

// edge positions
.ed-bottom {
  @include angle(after);
}

.ed-top {
  @include angle(before, true);
}

.ed-both {
   @include angle(after);
   @include angle(before, true);
}
nav {
	z-index: 1000;
}
h1{
	font-family: 'Inconsolata', sans-serif;

}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 120px;
		display: flex;
		align-items: center;


		@media (max-width: 768px) {
		    height: 45px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
@media (max-width:767px){
	.logo{max-height: 70px}
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

h1 {
	font-size: 4.0rem;
	letter-spacing: .0rem;
	color: rgb(0, 176, 234);
	text-transform: uppercase;
	font-weight: bold;

}

.cta {
	margin-top: 25px
}
.cta a {
	background: #00b0ea;
	padding: 15px 35px;
	color:#fff;
	font-weight: bold;
	font-size: 16px;
	font-family: 'Rubik', sans-serif;
	border-radius: 5px;
	text-decoration: none;

	&:hover {
		padding: 18px 38px
	}
}
// hero banner+

.hero-banner {
	height: 100vh;
	width: 100%;
	background: url('../img/hero-img.jpg') no-repeat center ;
	background-size: cover;
	@media(max-width: 1024px){
		background:url('../img/hero-img-mb.jpg');
		padding: 120px 0;
		height: auto;
	}
	h1 {
		margin-top: 25%;
		letter-spacing: 0.02em;
		@media(max-width: 767px){
					margin-top: 0%;
					font-size: 2.5em;
		}
	}
}
.banner {
	// height: 100vh;
	// width: 100%;
	background:#88203f ;
  	padding: 120px 0;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	@media(max-width: 1024px){
		// max-height: 500px;
		padding: 120px 0;
		height: auto;
	}
	h1 {
		// margin-top: 15%;
		font-size:3em;
		@media(max-width:767px){
				font-size:1.8em;
				margin-top: 0%;
		}
	}
	p {
		font-size: 1.8em;
		color: $wht;
		font-weight: lighter;
		 font-family: 'Rubik', sans-serif;
		 @media(max-width:767px){
				 font-size:1.2em
		 }
	}
}
.banner-last {
	height: 100vh;
	width: 100%;
	background:url('../img/bg-1.jpg') no-repeat top center;
	background-size: cover;
	@media(max-width: 1024px){
		background-image:url('../img/bg-1-mb.jpg') ;
		padding: 120px 0;
		height: auto;
	}
	h1 {
		margin-top: 20%;
		@media(max-width:767px){
				font-size:1.8em;
				margin-top: 0%;
		}
	}
	p {
		font-size: 1.8em;
		color: $wht;
		font-weight: lighter;
		 font-family: 'Rubik', sans-serif;
		 @media(max-width:767px){
				 font-size:1.2em;
		 }
	}
}
.form-section {
	margin: 60px 0;
}
.normal {
	transform: skewY(0deg);
}

.glow {
	 -ms-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.67)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.223333)) drop-shadow(0px 0px 2px rgba(224, 20, 222, 0.67));
	 -moz-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.67)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.223333)) drop-shadow(0px 0px 2px rgba(224, 20, 222, 0.67));
	 -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.67)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.223333)) drop-shadow(0px 0px 2px rgba(224, 20, 222, 0.67));
   filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.67)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.223333)) drop-shadow(0px 0px 2px rgba(224, 20, 222, 0.67));
 }
.intro-neon-sign {
	-ms-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 6px rgba(0, 176, 234, 0.6));
-moz-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 6px rgba(0, 176, 234, 0.6));
-webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 6px rgba(0, 176, 234, 0.6));
filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 6px rgba(0, 176, 234, 0.6));
}
.intro-neon-title{
	font-size:6rem;
	margin-bottom: 6rem;
	transform: translate(0);
	-webkit-transition: all .2s;
	transition: all .2s;
}
.neon * {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
}
.neon {
    position: relative;
	}

//  hero banner ends

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		@include btn2;
		display: block;
		width: 75%;
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}
@media(max-width: 544px){
	.hidden-mb{display: none;}
}
